import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3';
import numeral from 'numeral';

import { BigNumber } from '@ethersproject/bignumber';

import { chainMap, enforceChain } from './tools/ChainTools.js'
import { useInterval } from './tools/UseInterval.js'

import { truths } from './deliJS/farmgod-core.js'
import { Token } from './tools/token.js'

import {formatTokenBalance} from './tools/tokenUtilities'


import { Wallet } from './deliJS/wallet.js'

 import {Footer } from './deliJS/footer.js'


export function Dapp() {
  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");


  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     enforceChain("Fantom", letItRip)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      } 
  }

  // once the user is connected, add the accountsChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
      };
    }
  }, [connected]);


  // once the user is connected, add the chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      console.log(window.ethereum.chainId)
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('chainChanged', handleChainChange);
      }
    }
  }, [connected])
  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])


  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  const [isDisabled, setDisabled] = React.useState(false);



  var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');

var alchemists = new web3.eth.Contract(truths["alchemists"]["abi"], truths["alchemists"]["address"])

const Alchemist = (props) => (<div className="alchemist"><img src={props.img} /></div>)
const [alcaImg, setAlcaImg] = React.useState("");
const [alcaName, setAlcaName] = React.useState("");
const [alcaDesc, setAlcaDesc] = React.useState("");

const [alcaNum, setAlcaNum] = React.useState(1)
const [alcaOwner, setAlcaOwner] = React.useState("")

const letItRip = () => {
  getUserBalance()
}
const tokenId = React.useRef();
const [userBal, setUserBal] = React.useState(0)
const [tokenIndex, setTokenIndex] = React.useState(0)
const [activeToken, setActiveToken] = React.useState(0)
React.useEffect(() => {
  getUserAlchemistByIndex(tokenIndex)
},[userBal, tokenIndex])
React.useEffect(() => {
  getAlca(activeToken)
},[activeToken])

const getUserBalance = () => {
  alchemists.methods.balanceOf(window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress})
    .then((res)=> {
      setUserBal(res)
    })
}

const getUserAlchemistByIndex = (i) => {


        if (userBal > i) {
          alchemists.methods.tokenOfOwnerByIndex(window.ethereum.selectedAddress,i)
            .call({from: window.ethereum.selectedAddress})
          .then(

            (res) => {

              setActiveToken(res)
            })
        }
      
    
}  

const getAlca = (i) => {
 alchemists.methods.tokenURI(i).call(
        {from: window.ethereum.selectedAddress})
      .then(
        (res) => {
          var x = JSON.parse(atob(res.substring(29)))
          setAlcaImg(x.image)  
        })
  
}


  return (
    <div className={"App-wrapper " + " connected--" + connected}>

   
      <button disabled={isDisabled} onClick={connectMM} className="mmbtn">
        {mmBtnText}
      </button>
      <h2>You have {userBal} Alchemists</h2>
      <div>
        <div>
          <input ref={tokenId} />
          <button onClick={() => {

          setAlcaNum(tokenId.current.value) }}>Check</button>
        </div>
        <div>
          <h1>Alchemist #{activeToken}</h1>
  
        </div>
        <Alchemist img={alcaImg}/>
        <div>
          
          <button onClick={() => {
          let x = tokenIndex
          setTokenIndex(--x) }}>Back</button>
          
          <button onClick={() => {
          let x = tokenIndex
          setTokenIndex(++x) }}>Next</button>
          </div>
      </div>
      <Footer />
    </div>

  );
}

